import { computed, ref } from 'vue'
import EmbeddingIndex from '@/services/embeddingIndices'
import { getCommunityTypeOptions, getLocationOptions } from '@/services/results'

export const MAX_NUMBER_OF_RESULTS_OPTIONS = [
  {
    internal: 25,
    display: '25',
  },
  {
    internal: 50,
    display: '50',
  },
  {
    internal: 100,
    display: '100',
  },
]

export const DEAL_STAGE_OPTIONS = [
  {
    internal: 'Seed',
    display: 'Seed',
  },
  {
    internal: 'Early Stage Venture',
    display: 'Early Stage Venture',
  },
  {
    internal: 'Late Stage Venture',
    display: 'Late Stage Venture',
  },
]

export const COMPANY_SECTOR_OPTIONS = [
  { internal: 'AI/ML', display: 'AI/ML' },
  { internal: 'Ad Tech', display: 'Ad Tech' },
  { internal: 'Agritech', display: 'Agritech' },
  { internal: 'Artificial Intelligence', display: 'Artificial Intelligence' },
  { internal: 'Big Data', display: 'Big Data' },
  { internal: 'Biotech', display: 'Biotech' },
  { internal: 'Cleantech', display: 'Cleantech' },
  { internal: 'Consumer', display: 'Consumer' },
  { internal: 'Crypto', display: 'Crypto' },
  { internal: 'Data', display: 'Data' },
  { internal: 'Deeptech', display: 'Deeptech' },
  { internal: 'DevTools', display: 'DevTools' },
  { internal: 'eCommerce', display: 'eCommerce' },
  { internal: 'Education', display: 'Education' },
  { internal: 'Enterprise', display: 'Enterprise' },
  { internal: 'Enterprise Infra', display: 'Enterprise Infra' },
  { internal: 'Fin Tech', display: 'Fin Tech' },
  { internal: 'Gaming', display: 'Gaming' },
  { internal: 'HR Tech', display: 'HR Tech' },
  { internal: 'Hardware', display: 'Hardware' },
  { internal: 'Health & Wellness', display: 'Health & Wellness' },
  { internal: 'Healthcare', display: 'Healthcare' },
  { internal: 'In-store', display: 'In-store' },
  { internal: 'Insurance', display: 'Insurance' },
  { internal: 'Marketplaces', display: 'Marketplaces' },
  { internal: 'Mobile', display: 'Mobile' },
  { internal: 'SMBs', display: 'SMBs' },
  { internal: 'SaaS', display: 'SaaS' },
  { internal: 'Security', display: 'Security' },
  { internal: 'Social', display: 'Social' },
]

// sort alphabetical
const sortIndices = (a, b) => {
  const valueA = a.name.toLowerCase()
  const valueB = b.name.toLowerCase()

  if (valueA < valueB) return -1
  if (valueA > valueB) return 1
  return 0
}

export const useIndexConfig = () => {
  const indexId = ref('')
  const indices = ref([])
  const maxNumberOfResults = ref(Number(process.env.VUE_APP_DEFAULT_MAX_NUM_RESULTS))

  const index = computed(() => indices.value.find((i) => i.id === indexId.value) || null)
  const indexOptions = computed(() =>
    indices.value.map((i) => ({ internal: i.id, display: i.name })),
  )

  // fetch indices
  EmbeddingIndex.createCollection({
    pagination: {
      size: 99,
      page: 1,
    },
    filters: {
      hiddenFromUX: false,
    },
  })
    .refresh()
    .then((collection) => {
      indices.value = collection.list.sort(sortIndices)
      // set initial index value
      indexId.value = collection.list[0].id
    })

  return {
    index,
    indexId,
    indexOptions,
    maxNumberOfResults,
  }
}

export const useContactIndexFilterSet = () => {
  const communityTypes = ref([])
  const communityTypeOptions = ref([])
  const locations = ref([])
  const locationOptions = ref([])
  const onlySelfContacts = ref(false)
  const hasInternalContacts = ref(false)
  const hasLastInboundEmail = ref(false)
  const lastInboundEmailStart = ref(null)
  const lastInboundEmailEnd = ref(null)

  // fetch options
  getCommunityTypeOptions().then((options) => (communityTypeOptions.value = options))
  getLocationOptions().then((options) => (locationOptions.value = options))

  const contactIndexFilters = computed(() => ({
    communityTypes: communityTypes.value,
    locations: locations.value,
    onlySelfContacts: onlySelfContacts.value,
    hasInternalContacts: hasInternalContacts.value,
    hasLastInboundEmail: hasLastInboundEmail.value,
    lastInboundEmailStart: lastInboundEmailStart.value,
    lastInboundEmailEnd: lastInboundEmailEnd.value,
  }))

  function resetContactIndexFilters() {
    communityTypes.value = []
    locations.value = []
    onlySelfContacts.value = false
    hasInternalContacts.value = false
    hasLastInboundEmail.value = false
    lastInboundEmailStart.value = null
    lastInboundEmailEnd.value = null
  }

  return {
    communityTypes,
    communityTypeOptions,
    locations,
    locationOptions,
    onlySelfContacts,
    hasInternalContacts,
    hasLastInboundEmail,
    lastInboundEmailStart,
    lastInboundEmailEnd,
    contactIndexFilters,
    resetContactIndexFilters,
  }
}

export const useDealIndexFilterSet = () => {
  const dealStages = ref([])
  const companySectors = ref([])

  const dealIndexFilters = computed(() => ({
    dealStages: dealStages.value,
    companySectors: companySectors.value,
  }))

  function resetDealIndexFilters() {
    dealStages.value = []
    companySectors.value = []
  }

  return {
    dealStages,
    companySectors,
    dealIndexFilters,
    resetDealIndexFilters,
  }
}
