<template>
  <form @submit.prevent="handleSubmit()" class="flex flex-col">
    <TextAreaField
      key="titles"
      v-model:value="textFieldsForm.titles.value"
      :errors="textFieldsForm.titles.errors"
      :disabled="disabled"
      label="Titles"
      placeholder="Eg. CEO, CFO"
      @blur="textFieldsForm.titles.validate()"
    />
    <TextAreaField
      key="industries"
      v-model:value="textFieldsForm.industries.value"
      :errors="textFieldsForm.industries.errors"
      :disabled="disabled"
      label="Industries"
      placeholder="Eg. FinTech, HealthTech"
      @blur="textFieldsForm.industries.validate()"
    />
    <CheckboxFieldSet
      v-model:value="functionalAreaSelections"
      :options="FUNCTIONAL_AREAS"
      :disabled="disabled"
      label="Functional Areas"
      description="This is NOT a filter. Select all functional areas where expertise would be important to your search."
    />
    <TextAreaField
      key="workHistory"
      v-model:value="textFieldsForm.workHistory.value"
      :errors="textFieldsForm.workHistory.errors"
      :disabled="disabled"
      label="Work History"
      description="Highlight things you would expect to see in their work history."
      @blur="textFieldsForm.workHistory.validate()"
    />
    <button class="ml-auto outline-primary px-4 py-2" type="submit" :disabled="disabled">
      Submit
    </button>
  </form>
</template>

<script>
import { ref, computed } from 'vue'
import TextAreaField from '@/components/inputs/TextAreaField'
import CheckboxFieldSet from '@/components/inputs/CheckboxFieldSet'
import { BuilderTextFieldsForm } from '@/services/builder/forms'
import { FUNCTIONAL_AREAS } from '@/services/builder/constants'

function generateQueryFromBuilderData(data) {
  let query = ''
  if (data.titles) query += `Titles such as: ${data.titles}, or similar. `
  if (data.industries) query += `Industries or sectors such as: ${data.industries}, or similar. `
  data.functionalAreas.forEach((area) => {
    query += `Expertise in ${area} or similar. `
  })
  if (data.workHistory) query += `Work history in or with: ${data.workHistory}, or similar.`
  return query
}

export default {
  name: 'BuilderForm',
  components: { TextAreaField, CheckboxFieldSet },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submit'],
  setup(props, context) {
    // fields
    const textFieldsForm = ref(new BuilderTextFieldsForm())
    const functionalAreaSelections = ref({})

    const functionalAreasSelected = computed(() =>
      Object.entries(functionalAreaSelections.value)
        .filter((entry) => entry[1])
        .map((entry) => entry[0]),
    )

    const data = computed(() => ({
      titles: textFieldsForm.value.titles.value,
      industries: textFieldsForm.value.industries.value,
      workHistory: textFieldsForm.value.workHistory.value,
      functionalAreas: functionalAreasSelected.value,
    }))
    const query = computed(() => generateQueryFromBuilderData(data.value))

    function handleSubmit() {
      // validate fields
      // NOTE: currently no validations for fields outside of textFieldsForm
      textFieldsForm.value.validate()

      // only proceed if valid inputs
      if (!textFieldsForm.value.isValid) return
      // ensure at least one field has a value, since all are optional
      if (!query.value.length) return

      context.emit('submit', query.value, data.value)
    }

    return {
      textFieldsForm,
      FUNCTIONAL_AREAS,
      functionalAreaSelections,
      handleSubmit,
    }
  },
}
</script>
