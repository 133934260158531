import { objectToCamelCase } from '@thinknimble/tn-utils'
import AxiosClient from './AxiosClient'
import { apiErrorHandler } from './api'

export const waiiQuery = (query) => {
  const ENDPOINT = '/waii/query/'
  const data = { query }

  return AxiosClient.post(ENDPOINT, data)
    .then((r) => objectToCamelCase(r.data))
    .catch(apiErrorHandler({ apiName: 'waii.waiiQuery' }))
}

function salesforceObjectListViewUrl(sfObjectName) {
  return `https://firstround.lightning.force.com/lightning/o/${sfObjectName}/list`
}

export const TABLE_NAME_TO_SF_LIST_VIEW_URL = {
  SALESFORCE_COMPANY_INDUSTRY_SALESFORCECOMPANYINDUSTRY:
    salesforceObjectListViewUrl('Company_Industry__c'),
  SALESFORCE_COMPANY_SALESFORCECOMPANY: salesforceObjectListViewUrl('Account'),
  SALESFORCE_CONTACT_SALESFORCECONTACT: salesforceObjectListViewUrl('Contact'),
  SALESFORCE_DEAL_INVESTOR_SALESFORCEDEALINVESTOR: salesforceObjectListViewUrl(
    'Pitchbook_Deal_Investor__c',
  ),
  SALESFORCE_DEAL_SALESFORCEDEAL: salesforceObjectListViewUrl('Pitchbook_Deal__c'),
  SALESFORCE_INDUSTRY_SALESFORCEINDUSTRY: salesforceObjectListViewUrl('Industry__c'),
  SALESFORCE_WORK_HISTORY_SALESFORCEWORKHISTORY: salesforceObjectListViewUrl('Work_History__c'),
}

export const TABLE_NAME_TO_DISPLAY_NAME = {
  SALESFORCE_COMPANY_INDUSTRY_SALESFORCECOMPANYINDUSTRY: 'Company_Industry__c',
  SALESFORCE_COMPANY_SALESFORCECOMPANY: 'Account',
  SALESFORCE_CONTACT_SALESFORCECONTACT: 'Contact',
  SALESFORCE_DEAL_INVESTOR_SALESFORCEDEALINVESTOR: 'Pitchbook_Deal_Investor__c',
  SALESFORCE_DEAL_SALESFORCEDEAL: 'Pitchbook_Deal__c',
  SALESFORCE_INDUSTRY_SALESFORCEINDUSTRY: 'Industry__c',
  SALESFORCE_WORK_HISTORY_SALESFORCEWORKHISTORY: 'Work_History__c',
}
