<template>
  <div class="flex flex-row justify-center pl-4 pt-4">
    <LoadingSpinner />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'QueryLoading',
  components: { LoadingSpinner },
}
</script>
