// From https://firstround.lightning.force.com/lightning/setup/ObjectManager/Contact/FieldsAndRelationships/00N1600000EkXP2/view
export const FUNCTIONAL_AREAS = [
  'Analytics/Data',
  'Architecture',
  'Business Development',
  'Coaching',
  'Communications',
  'Community',
  'Customer Success',
  'Customer Support',
  'DDML',
  'Design',
  'Engineering/Development',
  'Finance',
  'Growth',
  'Hardware',
  'HR/People Operations',
  'IT',
  'Legal/Regulatory',
  'Marketing',
  'Operations/Business Operations',
  'Other',
  'Photography',
  'Product',
  'Real Estate',
  'Recruiting/Talent',
  'Research',
  'Sales',
  'Security',
  'Strategy',
]
