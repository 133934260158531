<template>
  <fieldset class="mb-4">
    <label v-if="label" :for="`${label}-field`" class="font-bold">
      {{ label }}
    </label>
    <p v-if="description" class="text-xs">{{ description }}</p>
    <CheckboxField
      v-for="o in options"
      :key="o"
      v-model:value="val[o]"
      :label="o"
      class="mt-2"
      :disabled="disabled"
    />
    <ul v-if="errors.length">
      <li
        v-for="(error, index) in errors"
        :key="index"
        v-text="error.message"
        class="input--error"
      />
    </ul>
  </fieldset>
</template>

<script>
import CheckboxField from '@/components/inputs/CheckboxField'

export default {
  name: 'CheckboxFieldSet',
  components: { CheckboxField },
  props: {
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      validator(value) {
        return value.every((e) => typeof e === 'string')
      },
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:value', 'change'],
  setup(props, context) {
    const dataHandler = {
      get(target, name) {
        // If the object property does not exist, return false
        // eslint-disable-next-line no-prototype-builtins
        return target.hasOwnProperty(name) ? target[name] : false
      },
      set(target, name) {
        target[name] = !target[name]
        context.emit('update:value', target)
        context.emit('change', target)
        return target
      },
    }

    const val = new Proxy(props.value, dataHandler)
    return { val }
  },
}
</script>
