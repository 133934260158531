<template>
  <div class="pt-4 hidden md:block">
    <!-- nav slot -->
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LeftColumn',
}
</script>
