<template>
  <div class="max-w-screen-xl w-full border-b border-gray-200 dark:border-gray-700">
    <nav class="flex space-x-2" aria-label="Tabs" role="tablist">
      <button
        v-for="tab in tabs"
        :key="tab.id"
        type="button"
        class="py-4 px-6 grow inline-flex items-center justify-center gap-2 border-b-[3px] border-transparent whitespace-nowrap font-semibold text-gray-500 outline-primary"
        :class="{
          'text-black border-application-dark-green': tab.id === currentTabId,
        }"
        role="tab"
        @click="$emit('select', tab)"
      >
        {{ tab.label }}
      </button>
    </nav>
  </div>

  <div class="max-w-screen-xl w-full mt-3">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    currentTabId: {
      type: String,
      default: null,
    },
  },
  emits: ['select'],
}
</script>
