<template>
  <SelectField
    class="mt-8 w-60 select-contact-community-types"
    label="Community Types"
    :options="communityTypeOptions"
    v-model:value="cTypes"
    multiple
    placeholder="Include All"
    :help="
      'Contacts that belong to any of the selected communities will be included.<br /> <br />' +
      communityTypeOptions
        .map((o) => `<strong>${o.display}</strong>${o.description ? `: ${o.description}` : ''}`)
        .join('<hr />')
    "
    :disabled="disabled"
  />
  <SelectField
    class="w-60 mt-8 select-contact-locations"
    label="Locations"
    :options="locationOptions"
    v-model:value="locs"
    multiple
    placeholder="Include All"
    :help="
      'Contacts in any of the selected locations will be included.<br /> <br />' +
      locationOptions
        .map((o) => `<strong>${o.display}</strong>${o.description ? `: ${o.description}` : ''}`)
        .join('<hr />')
    "
    :disabled="disabled"
  />
  <label class="font-bold block mt-8 mb-2">Only My Contacts</label>
  <ToggleField v-model:value="sContacts" :disabled="disabled" className="toggle-only-my-contacts" />
  <label class="font-bold block mt-8 mb-2">Has Internal Contacts</label>
  <ToggleField
    v-model:value="iContacts"
    :disabled="disabled"
    className="toggle-has-internal-contacts"
  />
  <LabelWithTooltip
    label="Has Last Inbound Email"
    :help="`Contacts that have any inbound email to FRC will be included if this is toggled to Yes.`"
  />
  <ToggleField
    v-model:value="lInbound"
    :disabled="disabled"
    className="toggle-has-last-inbound-email"
  />
  <div v-if="lInbound" class="mt-8 w-60">
    <label class="font-bold block mb-2">Last Inbound Email Date Range</label>
    <input type="date" v-model="startDate" class="input-date-range" :disabled="disabled"/>
    <span class="mx-2">to</span>
    <input type="date" v-model="endDate" class="input-date-range" :disabled="disabled"/>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import SelectField from '@/components/inputs/SelectField'
import ToggleField from '@/components/inputs/ToggleField'
import LabelWithTooltip from '@/components/inputs/LabelWithTooltip'
import { trackHeapEvent } from '@/services/metrics'
export default {
  name: 'ContactIndexFilterSet',
  components: { SelectField, ToggleField, LabelWithTooltip },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    communityTypes: {
      type: Array,
      required: true,
    },
    communityTypeOptions: {
      type: Array,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    locationOptions: {
      type: Array,
      required: true,
    },
    onlySelfContacts: {
      type: Boolean,
      required: true,
    },
    hasInternalContacts: {
      type: Boolean,
      required: true,
    },
    hasLastInboundEmail: {
      type: Boolean,
      required: true,
    },
    lastInboundEmailStart: {
      type: String,
      required: false,
    },
    lastInboundEmailEnd: {
      type: String,
      required: false,
    },
  },
  emits: [
    'close',
    'update:communityTypes',
    'update:locations',
    'update:onlySelfContacts',
    'update:hasInternalContacts',
    'update:hasLastInboundEmail',
    'update:lastInboundEmailStart',
    'update:lastInboundEmailEnd',
  ],
  setup(props, context) {
    const cTypes = computed({
      get() {
        return props.communityTypes
      },
      set(value) {
        trackHeapEvent('Set Contact Community Types Filter', { value })
        context.emit('update:communityTypes', value)
      },
    })
    const locs = computed({
      get() {
        return props.locations
      },
      set(value) {
        trackHeapEvent('Set Contact Locations Filter', { value })
        context.emit('update:locations', value)
      },
    })
    const sContacts = computed({
      get() {
        return props.onlySelfContacts
      },
      set(value) {
        trackHeapEvent('Set Contact Only Self Contacts Filter', { value })
        context.emit('update:onlySelfContacts', value)
      },
    })
    const iContacts = computed({
      get() {
        return props.hasInternalContacts
      },
      set(value) {
        trackHeapEvent('Set Contact Has Internal Contacts Filter', { value })
        context.emit('update:hasInternalContacts', value)
      },
    })
    const lInbound = computed({
      get() {
        return props.hasLastInboundEmail
      },
      set(value) {
        trackHeapEvent('Set Contact Has Last Inbound Email Filter', { value })
        context.emit('update:hasLastInboundEmail', value)
      },
    })

    const startDate = computed({
      get() {
        return props.lastInboundEmailStart
      },
      set(value) {
        trackHeapEvent('Set Contact Last Inbound Email Start Date Filter', { value })
        context.emit('update:lastInboundEmailStart', value)
      },
    })

    const endDate = computed({
      get() {
        return props.lastInboundEmailEnd
      },
      set(value) {
        trackHeapEvent('Set Contact Last Inbound Email End Date Filter', { value })
        context.emit('update:lastInboundEmailEnd', value)
      },
    })
    return {
      cTypes,
      locs,
      sContacts,
      iContacts,
      lInbound,
      startDate,
      endDate,
    }
  },
}
</script>
