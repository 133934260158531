<template>
  <StandardPageWrapper>
    <div class="text-left flex flex-col">
      <h1 class="text-5xl font-black mb-6">Waii Query [Test]</h1>
      <form class="max-w-lg" @submit.prevent="attemptQuery()">
        <TextAreaField
          v-model:value="query"
          label="Query"
          description="Query our salesforce data in natural language."
          placeholder="Get 5 contacts that worked at uber in the last 5 years."
          class="mb-0"
        />
        <TextAreaField
          v-model:value="fieldsToInclude"
          label="Information To Include"
          description="Detail what information you would like to see per search result."
          placeholder="Include contact name, LinkedIn URL, current company name, current title"
          class="mb-0"
        />
        <button class="w-max bg-primary text-white font-semibold p-3 rounded" type="submit">
          Search
        </button>
      </form>
    </div>
  </StandardPageWrapper>
  <Modal v-if="results" @close="results = null" classes="max-w-7xl flex flex-col text-left">
    <h1 class="text-2xl font-black mb-6">Results</h1>
    <p v-if="!results?.length">NO RESULTS</p>
    <WaiiQueryResultsTable v-else :results="results" class="mb-6" />
    <div v-if="tablesQueried" class="mb-6">
      <h1 class="text-2xl font-black mb-6">Relevant Salesforce Tables</h1>
      <ul>
        <li v-for="tName in tablesQueried" :key="tName">
          <a
            :href="salesforceTableUrl(tName)"
            target="_blank"
            class="text-blue-600 dark:text-blue-500 hover:underline"
            >{{ salesforceTableDisplayName(tName) }}</a
          >
        </li>
      </ul>
    </div>
  </Modal>
  <GlobalLoading v-if="loading" />
</template>

<script>
import {
  waiiQuery,
  TABLE_NAME_TO_SF_LIST_VIEW_URL,
  TABLE_NAME_TO_DISPLAY_NAME,
} from '@/services/waii'
import StandardPageWrapper from '@/components/StandardPageWrapper/StandardPageWrapper'
import TextAreaField from '@/components/inputs/TextAreaField'
import GlobalLoading from '@/components/GlobalLoading'
import Modal from '@/components/Modal'
import WaiiQueryResultsTable from '@/components/WaiiQuery/WaiiQueryResultsTable'

export default {
  name: 'WaiiQuery',
  components: {
    StandardPageWrapper,
    TextAreaField,
    GlobalLoading,
    Modal,
    WaiiQueryResultsTable,
  },
  data() {
    return {
      query: '',
      fieldsToInclude: '',
      loading: false,
      results: null,
      tablesQueried: null,
    }
  },
  methods: {
    attemptQuery() {
      if (!this.query) return
      if (!this.fieldsToInclude) return

      this.results = null
      this.tablesQueried = null
      this.loading = true

      let finalQuery = this.query

      if (!this.fieldsToInclude.toLowerCase().includes('include')) {
        finalQuery += ' Please include'
      }

      finalQuery += ' ' + this.fieldsToInclude

      waiiQuery(finalQuery)
        .then((r) => {
          this.results = r.results
          this.tablesQueried = r.tablesQueried
        })
        .finally(() => (this.loading = false))
    },
    salesforceTableUrl(tName) {
      return TABLE_NAME_TO_SF_LIST_VIEW_URL[tName]
    },
    salesforceTableDisplayName(tName) {
      const dName = TABLE_NAME_TO_DISPLAY_NAME[tName]
      return `${dName} ↗️`
    },
  },
}
</script>
