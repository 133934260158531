<template>
  <div
    class="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 border rounded-lg"
  >
    <div
      class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      aria-hidden="true"
    >
      <div
        class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-application-light-green to-application-dark-green opacity-50 opacity-60"
        style="
          clip-path: polygon(
            74.8% 41.9%,
            97.2% 73.2%,
            100% 34.9%,
            92.5% 0.4%,
            87.5% 0%,
            75% 28.6%,
            58.5% 54.6%,
            50.1% 56.8%,
            46.9% 44%,
            48.3% 17.4%,
            24.7% 53.9%,
            0% 27.9%,
            11.9% 74.2%,
            24.9% 54.1%,
            68.6% 100%,
            74.8% 41.9%
          );
        "
      ></div>
    </div>
    <div class="flex flex-wrap items-center gap-x-4 gap-y-2 w-full py-2">
      <p class="text-sm leading-6 text-gray-900">
        Welcome to FRC Yellowpages! Ask our AI to surface FRC contacts based on your criteria.<br /><br />
        <strong class="font-bold">NOTE:</strong> Include as much context as you can in the query you
        send. This query is used to determine the most relevant contacts.
      </p>
    </div>
    <div class="flex flex-1 justify-end"></div>
  </div>
</template>
