<template>
  <label
    class="relative inline-flex items-center cursor-pointer"
    :class="{ 'pointer-events-none': disabled }"
  >
    <input
      type="checkbox"
      v-model="val"
      :class="`sr-only peer ${className}`"
      :disabled="disabled"
    />
    <div
      class="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"
    />
    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
      {{ val ? 'Yes' : 'No' }}
    </span>
  </label>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'ToggleField',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
  emits: ['update:value'],
  setup(props, context) {
    const val = computed({
      get: () => props.value,
      set: (value) => context.emit('update:value', value),
    })

    // emit initial value, to ensure there is an initial value in the form field
    context.emit('update:value', !!props.value)

    return { val }
  },
}
</script>
