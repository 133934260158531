import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
import AxiosClient from './AxiosClient'
import { apiErrorHandler } from './api'

export const requestResultsAsCSV = (contacts) => {
  const ENDPOINT = '/results/csv/'

  const data = {
    contacts: contacts.map(objectToSnakeCase),
  }
  return AxiosClient.post(ENDPOINT, data)
    .then((r) => r.data)
    .catch(apiErrorHandler({ apiName: 'results.requestContactsDataAsCSV' }))
}

export const requestResultsAsGoogleSpreadsheet = (contactIds) => {
  const ENDPOINT = '/results/google-spreadsheet/'
  const data = { contactIds }

  return AxiosClient.post(ENDPOINT, objectToSnakeCase(data))
    .then((r) => objectToCamelCase(r.data))
    .catch(apiErrorHandler({ apiName: 'results.requestResultsAsGoogleSpreadsheet' }))
}

export const getCommunityTypeOptions = () => {
  const ENDPOINT = '/results/community-types/options/'

  return AxiosClient.get(ENDPOINT)
    .then((r) => r.data.map(objectToCamelCase))
    .catch(apiErrorHandler({ apiName: 'results.getCommunityTypeChoices' }))
}

export const getLocationOptions = () => {
  const ENDPOINT = '/results/locations/options/'

  return AxiosClient.get(ENDPOINT)
    .then((r) => r.data.map(objectToCamelCase))
    .catch(apiErrorHandler({ apiName: 'results.getLocationOptions' }))
}
