<template>
  <AgGridVue
    :rowData="rowData"
    :columnDefs="colDefs"
    class="ag-theme-quartz h-full"
    allowDragFromColumnsToolPanel
    domLayout="autoHeight"
  />
</template>

<script>
import { ref } from 'vue'
import { AgGridVue } from 'ag-grid-vue3'

function createLinkTag(href, innerText = 'Link ↗️') {
  const a = document.createElement('a')
  a.href = href
  a.innerText = innerText
  a.target = '_blank'
  a.className = 'text-blue-600 dark:text-blue-500 hover:underline'
  a.style.cursor = 'pointer'

  return a
}

export default {
  name: 'WaiiQueryResultsTable',
  components: {
    AgGridVue,
  },
  props: {
    results: {
      type: Array,
      required: true,
      validator(value) {
        // At least one result is required
        return !!value.length
      },
    },
  },
  setup(props) {
    // use the 0th result's keys to generate columns
    const columns = Object.keys(props.results[0]).map((k) => {
      return {
        field: k,
        // upcase first letter, replace _ with space
        headerName: (k.charAt(0).toUpperCase() + k.slice(1)).replaceAll('_', ' '),
        cellRenderer: (params) => {
          if (params.colDef.field === 'ID') {
            // while this is a URL for Contact, Salesforce will correct to proper object type
            const salesforceUrl = `https://firstround.lightning.force.com/lightning/r/Contact/${params.value}/view`
            return createLinkTag(salesforceUrl, params.value + ' ↗️')
          }

          try {
            new URL(params.value)
            return createLinkTag(params.value)
          } catch {
            //
          }

          return params.value
        },
      }
    })

    const colDefs = ref(columns)
    const rowData = ref(props.results)

    return {
      rowData,
      colDefs,
    }
  },
}
</script>

<style src="ag-grid-community/styles/ag-grid.css"></style>
<style src="ag-grid-community/styles/ag-theme-quartz.css"></style>
