import Model, { fields } from '@thinknimble/tn-models'

import QueryResultFeedbackAPI from './api'

export default class QueryResultFeedback extends Model {
  static api = QueryResultFeedbackAPI.create(QueryResultFeedback)

  static id = new fields.CharField({ readOnly: true })
  static datetimeCreated = new fields.CharField({ readOnly: true })
  static datetimeUpdated = new fields.CharField({ readOnly: true })
  static clientId = new fields.CharField()
  static contactId = new fields.CharField()
  static contactName = new fields.CharField()
  static value = new fields.IntegerField()
}
