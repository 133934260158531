<template>
  <StandardPageWrapper :maxWidth="submitted ? 'max-w-7xl' : 'max-w-4xl'">
    <template v-slot:left-nav>
      <button
        class="bg-white text-black font-semibold py-3 px-6 rounded border-gray drop-shadow-lg border"
        @click="reload()"
      >
        New Search
      </button>
      <SelectField
        class="w-60 mt-8"
        label="Use Case"
        :options="indexOptions"
        v-model:value="indexId"
        :allowEmpty="false"
        :help="
          'Selecting your use case will help us provide you with more relevant results.' +
          'This is an experimental feature, feedback is welcome!'
        "
        :disabled="submitted"
      />
      <SelectField
        class="w-60 mt-8"
        label="Max Number of Results"
        :options="MAX_NUMBER_OF_RESULTS_OPTIONS"
        v-model:value="maxNumberOfResults"
        :allowEmpty="false"
        :disabled="submitted"
      />
      <hr class="mt-8" />
      <ContactIndexFilterSet
        v-if="index?.isContactIndex"
        :disabled="submitted"
        :communityTypeOptions="communityTypeOptions"
        :locationOptions="locationOptions"
        v-model:communityTypes="communityTypes"
        v-model:locations="locations"
        v-model:onlySelfContacts="onlySelfContacts"
        v-model:hasInternalContacts="hasInternalContacts"
        v-model:hasLastInboundEmail="hasLastInboundEmail"
        v-model:lastInboundEmailStart="lastInboundEmailStart"
        v-model:lastInboundEmailEnd="lastInboundEmailEnd"
      />
    </template>
    <template v-slot:mobile-nav>
      <button
        class="bg-white text-black font-semibold py-3 px-8 rounded border-gray drop-shadow-lg border mb-6"
        @click="reload()"
      >
        New Chat
      </button>
      <SelectField
        class="w-60 mb-6"
        label="Use Case"
        :options="indexOptions"
        v-model:value="indexId"
        :help="
          'Selecting your use case will help us provide you with more relevant results.' +
          'This is an experimental feature, feedback is welcome!'
        "
        :disabled="submitted"
      />
      <SelectField
        class="w-60 mt-8"
        label="Max Number of Results"
        :options="MAX_NUMBER_OF_RESULTS_OPTIONS"
        v-model:value="maxNumberOfResults"
        :disabled="submitted"
      />
      <ContactIndexFilterSet
        v-if="index?.isContactIndex"
        :disabled="submitted"
        :communityTypeOptions="communityTypeOptions"
        :locationOptions="locationOptions"
        v-model:communityTypes="communityTypes"
        v-model:locations="locations"
        v-model:onlySelfContacts="onlySelfContacts"
        v-model:hasInternalContacts="hasInternalContacts"
        v-model:hasLastInboundEmail="hasLastInboundEmail"
        v-model:lastInboundEmailStart="lastInboundEmailStart"
        v-model:lastInboundEmailEnd="lastInboundEmailEnd"
      />
      <hr />
    </template>
    <div v-if="!submitted" class="flex flex-col grow px-2 md:px-6 pt-6">
      <QueryBanner class="mb-4" />
      <Tabs :tabs="TABS" :currentTabId="currentTabId" @select="handleTabSelect">
        <div v-if="currentTabId === BUILDER_TAB.id" class="shrink basis-auto mt-4">
          <BuilderForm @submit="attemptBuilderSubmit" :disabled="submitting" />
        </div>
        <div v-if="currentTabId === FREEFORM_TAB.id" class="shrink basis-auto mt-4">
          <form @submit.prevent="attemptFreeFormSubmit" class="flex flex-col mb-4">
            <TextAreaField
              v-model:value="input"
              :disabled="submitting"
              placeholder="Type your query for FRC Yellowpages here"
            />
            <button class="ml-auto outline-primary px-4 py-2" type="submit" :disabled="submitting">
              Submit
            </button>
          </form>
          <h2 class="font-bold mb-4">Example Queries:</h2>
          <div
            v-for="(example, idx) in EXAMPLE_QUERIES"
            :key="idx"
            class="border rounded-md relative p-4 mb-8"
          >
            <h3 class="italic text-gray-400 mb-4">Example #{{ idx + 1 }}</h3>
            <p
              v-for="(sentence, idx) in example.split('.').filter((s) => s)"
              :key="idx"
              class="block"
            >
              {{ sentence + '.' }}
            </p>
            <ClipboardDocumentIcon
              class="absolute top-2 right-2 h-5 w-5 cursor-pointer text-primary ml-auto"
              @click="copyToClipboard(example)"
            />
          </div>
        </div>
      </Tabs>
      <QueryLoading v-if="submitting" />
    </div>
    <template v-else>
      <h3 class="flex flex-row items-center space-x-auto font-bold mb-4">
        <span>Query:</span>
        <ClipboardDocumentIcon
          v-if="input"
          class="h-5 w-5 cursor-pointer text-primary ml-auto"
          @click="copyToClipboard(input)"
        />
      </h3>
      <div>{{ input || 'None. Strict filters search.' }}</div>
      <hr class="my-6" />
      <div class="flex items-start">
        <h3 class="font-bold mb-6">Results:</h3>
        <div class="ml-auto">
          <button
            v-if="index.isContactIndex"
            class="text-green-600 ml-auto"
            @click.prevent="attemptExportCSV()"
          >
            Export CSV
          </button>
          <span v-if="index.isContactIndex" class="mx-6">|</span>
          <button
            v-if="index.isContactIndex"
            class="text-green-600"
            @click.prevent="attemptCreateGoogleSpreadsheet()"
          >
            View in Sheets
          </button>
        </div>
      </div>
      <ContactIndexQueryResultsTable
        v-if="index?.isContactIndex"
        :contacts="results"
        @feedback="attemptCreateFeedback"
      />
    </template>
  </StandardPageWrapper>
</template>

<script>
import { v4 } from 'uuid'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import {
  useIndexConfig,
  useContactIndexFilterSet,
  MAX_NUMBER_OF_RESULTS_OPTIONS,
} from '@/composables/query'
import { errorToast, successToast } from '@/services/toastify'
import { requestResultsAsCSV, requestResultsAsGoogleSpreadsheet } from '@/services/results'
import { trackHeapEvent } from '@/services/metrics'
import EmbeddingIndex from '@/services/embeddingIndices'
import QueryResultFeedback from '@/services/queryResultFeedback'

import { ClipboardDocumentIcon } from '@heroicons/vue/24/outline'
import StandardPageWrapper from '@/components/StandardPageWrapper/StandardPageWrapper'
import Tabs from '@/components/Tabs'
import TextAreaField from '@/components/inputs/TextAreaField'
import SelectField from '@/components/inputs/SelectField'
import ToggleField from '@/components/inputs/ToggleField'
import QueryBanner from '@/components/Query/QueryBanner'
import QueryLoading from '@/components/Query/QueryLoading'
import ContactIndexQueryResultsTable from '@/components/Query/ContactIndexQueryResultsTable.vue'
import BuilderForm from '@/components/Query/BuilderForm/BuilderForm'
import ContactIndexFilterSet from '@/components/Query/ContactIndexFilterSet'

const FREEFORM_TAB = { id: 'freeform', label: 'Free-Form' }
const BUILDER_TAB = { id: 'builder', label: 'Builder' }
const TABS = [FREEFORM_TAB, BUILDER_TAB]

const EXAMPLE_QUERY_1 = `Titles such as: CEO, President, Executive Chair, or similar.
Industries or sectors such as: Healthcare Technology, Biotechnology, or similar.
Expertise in Growth or similar.
Expertise in Sales or similar.`

const EXAMPLE_QUERY_2 = `Titles such as: Lead Software Engineer, Staff Software Engineer, or similar.
Industries or sectors such as: Developer tools, Business Intelligence (BI) Tools, or similar.
Expertise in Engineering/Development or similar.
Expertise in Analytics/Data or similar.
Expertise in Security or similar.
Work history in or with: High growth startups, or similar.`

const EXAMPLE_QUERIES = [EXAMPLE_QUERY_1, EXAMPLE_QUERY_2]

export default {
  name: 'Query',
  components: {
    ClipboardDocumentIcon,
    StandardPageWrapper,
    Tabs,
    TextAreaField,
    SelectField,
    ToggleField,
    QueryBanner,
    QueryLoading,
    ContactIndexQueryResultsTable,
    BuilderForm,
    ContactIndexFilterSet,
  },
  setup() {
    const queryId = v4()
    const store = useStore()
    const currentTabId = ref(FREEFORM_TAB.id)
    const input = ref('')
    const submitting = ref(false)
    const submitted = ref(false)
    // const results = ref([])
    const response = ref({})
    const results = computed(() => response.value.results || [])

    const { index, indexId, indexOptions, maxNumberOfResults } = useIndexConfig()
    const {
      communityTypes,
      communityTypeOptions,
      locations,
      locationOptions,
      onlySelfContacts,
      hasInternalContacts,
      hasLastInboundEmail,
      lastInboundEmailStart,
      lastInboundEmailEnd,
      contactIndexFilters,
    } = useContactIndexFilterSet()

    watch(indexId, () => (input.value = ''))

    function handleTabSelect(tab) {
      trackHeapEvent('Contact Query Tab Selected', {
        tabId: tab.id,
        tabLabel: tab.label,
        userId: store.state.user.id,
        userEmail: store.state.user.email,
      })
      currentTabId.value = tab.id
    }

    async function submitQuery(content = '') {
      if (submitting.value) return

      submitting.value = true
      store.dispatch('setLoading', true)

      // based on index type, collect filters
      let filters = {}
      if (index.value.isContactIndex) {
        filters = { ...contactIndexFilters.value }
      } else if (index.value.isCompanyIndex) {
        console.log('index.value.isCompanyIndex')
      }

      response.value = await EmbeddingIndex.api.query(
        indexId.value,
        content,
        maxNumberOfResults.value,
        filters,
      )

      submitting.value = false
      store.dispatch('setLoading', false)
    }

    async function attemptBuilderSubmit(query) {
      trackHeapEvent('Contact Query Results Submitted From Builder', {
        query,
        index: index.value.name,
        maxNumberOfResults: maxNumberOfResults.value,
        filters: contactIndexFilters.value,
        userId: store.state.user.id,
        userEmail: store.state.user.email,
      })
      input.value = query
      await submitQuery(input.value)
      submitted.value = true
    }

    async function attemptFreeFormSubmit() {
      trackHeapEvent('Contact Query Results Submitted From Freeform', {
        query: input.value,
        index: index.value.name,
        filters: contactIndexFilters.value,
        maxNumberOfResults: maxNumberOfResults.value,
        userId: store.state.user.id,
        userEmail: store.state.user.email,
      })
      await submitQuery(input.value)
      submitted.value = true
    }

    async function attemptExportCSV() {
      trackHeapEvent('Contact Query Results Exported', {
        query: input.value,
        index: index.value.name,
        maxNumberOfResults: maxNumberOfResults.value,
        filters: contactIndexFilters.value,
        results: results.value.map((contact) => contact.id),
        userId: store.state.user.id,
        userEmail: store.state.user.email,
      })
      const csv = await requestResultsAsCSV(results.value)
      const url = window.URL.createObjectURL(new Blob([csv]))

      // Get a formatted date string
      const now = new Date()
      const dateString = now.toISOString().split('T')[0] // Format: YYYY-MM-DD

      // Truncate the input value if it's too long
      const maxLength = 50 // Maximum length of input value in file name
      let truncatedInput = input.value.slice(0, maxLength)
      if (input.value.length > maxLength) {
        truncatedInput += '...' // Indicate that the input was truncated
      }

      // Construct the file name
      const fileName = `${
        index.value ? index.value.name + '-' : ''
      }${truncatedInput}-${dateString}-query-results.csv`

      // Download the file
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      link.click()
    }

    function attemptCreateGoogleSpreadsheet() {
      trackHeapEvent('Contact Query Results Exported to Google Sheets', {
        query: input.value,
        index: index.value.name,
        maxNumberOfResults: maxNumberOfResults.value,
        filters: contactIndexFilters.value,
        results: results.value.map((contact) => contact.id),
        userId: store.state.user.id,
        userEmail: store.state.user.email,
      })
      store
        .dispatch('setLoading', true)
        .then(() => requestResultsAsGoogleSpreadsheet(results.value.map((contact) => contact.id)))
        .then(({ spreadsheetUrl }) => {
          const link = document.createElement('a')
          link.href = spreadsheetUrl
          link.target = '_blank'
          link.click()
        })
        .finally(() => store.dispatch('setLoading', false))
    }

    function copyToClipboard(text) {
      trackHeapEvent('Copied Contact Query to Clipboard', {
        text,
        userId: store.state.user.id,
        userEmail: store.state.user.email,
      })
      navigator.clipboard
        .writeText(text)
        .then(() => {
          successToast('Copied to cliboard.')
        })
        .catch((error) => {
          errorToast(`Copy failed:\n ${error}`)
        })
    }

    function attemptCreateFeedback(contact, feedbackValue) {
      trackHeapEvent('Contact Query Result Feedback', {
        query: input.value,
        index: index.value.name,
        maxNumberOfResults: maxNumberOfResults.value,
        filters: contactIndexFilters.value,
        results: results.value.map((contact) => contact.id),
        userId: store.state.user.id,
        userEmail: store.state.user.email,
        contactId: contact.id,
        feedbackValue,
      })
      QueryResultFeedback.api
        .create(
          queryId,
          results.value.map((c) => c.id),
          input.value,
          contact.id,
          contact.fullName,
          feedbackValue,
        )
        .then(() => successToast('Feedback submitted'))
    }

    return {
      FREEFORM_TAB,
      BUILDER_TAB,
      TABS,
      EXAMPLE_QUERIES,
      currentTabId,
      handleTabSelect,
      input,
      submitting,
      submitted,
      results,
      // index config
      indexId,
      index,
      indexOptions,
      maxNumberOfResults,
      MAX_NUMBER_OF_RESULTS_OPTIONS,
      // contact index filters
      communityTypes,
      onlySelfContacts,
      hasInternalContacts,
      hasLastInboundEmail,
      lastInboundEmailStart,
      lastInboundEmailEnd,
      communityTypeOptions,
      locations,
      locationOptions,
      // handlers
      attemptFreeFormSubmit,
      attemptBuilderSubmit,
      attemptExportCSV,
      attemptCreateGoogleSpreadsheet,
      attemptCreateFeedback,
      copyToClipboard,
      reload() {
        window.location.reload()
      },
      showDebugUI: true,
    }
  },
}
</script>
