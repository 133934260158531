<template>
  <div class="flex flex-col items-start mb-4 w-full">
    <label v-if="label" :for="`${label}-field`" class="font-bold mb-1">
      {{ label }}<span v-if="required" class="text-primary">*</span>
      <Popper v-if="help" arrow :placement="helpPlacement">
        <InformationCircleIcon :class="`h-5 w-5 cursor-pointer tooltip-${label}`" />
        <template #content>
          <div class="bg-white rounded-lg p-2 border font-normal max-w-sm" v-html="help" />
        </template>
      </Popper>
    </label>
    <p v-if="description" class="text-xs">{{ description }}</p>
    <!-- NOTE: assumes each option object was generated by method choice_as_dict in choices.py -->
    <!-- hence props.label and props.trackBy being hard-coded -->
    <VueMultiselect
      class="mt-2"
      v-model="val"
      :options="options"
      label="display"
      trackBy="internal"
      :placeholder="placeholder"
      :close-on-select="true"
      :multiple="multiple"
      :showLabels="false"
      :disabled="disabled"
      :openDirection="openDirection"
      :allowEmpty="allowEmpty"
      @close="$emit('close', $event)"
    />
    <ul v-if="errors.length">
      <li
        v-for="(error, index) in errors"
        :key="index"
        v-text="error.message"
        class="input--error"
      />
    </ul>
  </div>
</template>

<script>
import { computed } from 'vue'
import VueMultiselect from 'vue-multiselect'
import { InformationCircleIcon } from '@heroicons/vue/24/solid'

export default {
  name: 'SelectField',
  components: { VueMultiselect, InformationCircleIcon },
  props: {
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Select one',
    },
    help: {
      type: String,
    },
    helpPlacement: {
      type: String,
      default: 'right-end',
    },
    value: {
      type: [null, String, Number, Array],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      required: true,
    },
    openDirection: {
      type: String,
      default: 'bottom',
    },
  },
  emits: ['close', 'update:value'],
  setup(props, context) {
    const val = computed({
      get: () => {
        // either string or array of strings
        if (props.multiple && props.value) {
          // for every selected value (str), find the option that represents it
          return props.value.map((v) => props.options.find((o) => o.internal === v))
        } else if (props.multiple) {
          // if no selection for a multi-select, return empty array
          return []
        } else {
          // if single-select dropdown, find the option that represents the current selection
          // or return null because no option is selected
          return props.options.find((o) => o.internal === props.value) || null
        }
      },
      set: (selected) => {
        if (props.multiple) {
          // args.selected is an array of selections (or empty due to de-selection)
          const value = selected.map((option) => option.internal)
          context.emit('update:value', value)
        } else {
          // args.selected is a single option object (or null due to de-selection)
          const value = selected?.internal || null
          context.emit('update:value', value)
        }
      },
    })

    return { val }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
