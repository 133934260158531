import { ModelAPI } from '@thinknimble/tn-models'
import { objectToSnakeCase } from '@thinknimble/tn-utils'

import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const QUERY_RESULT_FEEDBACK_ENDPOINT = '/query-result-feedback/'

export default class QueryResultFeedbackAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = QUERY_RESULT_FEEDBACK_ENDPOINT

  create(queryId, allContactIds, query, contactId, contactName, value) {
    const data = { queryId, allContactIds, query, contactId, contactName, value }
    return this.client
      .post(QUERY_RESULT_FEEDBACK_ENDPOINT, objectToSnakeCase(data))
      .then((response) => this.cls.fromAPI(response.data))
      .catch(apiErrorHandler({ apiName: 'QueryResultFeedbackAPI.create' }))
  }
}
